import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={39.581} height={39.58} viewBox="0 0 39.581 39.58" {...props}>
      <g data-name="Group 73">
        {/* Outer Circle */}
        <circle 
          cx="19.79" 
          cy="19.79" 
          r="19.79" 
          fill="#c4c4c4" // Light grey for the outer circle
        />
        {/* Inner Circle */}
        <circle 
          cx="19.79" 
          cy="19.79" 
          r="12" 
          fill="#7620ff" // Purple for the inner circle
        />
        {/* Interactive Element (Smaller Circle) */}
        <circle 
          cx="19.79" 
          cy="19.79" 
          r="6" 
          fill="#0b093b" // Dark grey for the interactive element
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
