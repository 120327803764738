import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/guide3.webp";
import AddImage2 from "../../assets/img/add/guide4.webp";
import AddImage3 from "../../assets/img/add/guide2.webp";
import AddImage4 from "../../assets/img/add/guide1.webp";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font13">
              At Kupeel, we offer a range of services designed to enhance your museum's visitor experience through audio.
              <br />
              From audio guide creation to personalized content, we've got you covered.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Audio Guide Creation"
                subtitle="High-quality audio guides tailored to your exhibits, enhancing visitor engagement and learning."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Personalized Content"
                subtitle="Custom audio content that reflects the unique stories and artifacts in your museum."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Multi-Language Support"
                subtitle="Reach a global audience with our multi-language audio guide capabilities."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Interactive Tours" subtitle="Engage visitors with interactive and immersive audio tours." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
          <Advertising className="flexSpaceCenter">
  <AddLeft>
    <h4 className="font15 semiBold">Why Choose Kupeel?</h4>
    <h2 className="font40 extraBold">AI-Powered Audio Magic</h2>
    <p className="font12">
      Experience the future of museum audio guides with Kupeel. Our AI-generated voices are so lifelike and engaging that it feels like having a podcaster expertly recording right in front of the art. Enjoy detailed, interesting, and immersive storytelling that elevates your visit.
    </p>
    <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
  <div style={{ width: "190px" }}>
    <FullButton
      title="Get Started"
      action={() => window.location.href = 'https://play.kupeel.com'}
    />
  </div>
  <div style={{ width: "190px", marginLeft: "15px" }}>
    <FullButton
      title="Contact Us"
      action={() => window.location.hash = 'contact'}
      border
    />
  </div>
</ButtonsRow>

  </AddLeft>
  <AddRight>
    <AddRightInner>
      <div className="flexNullCenter">
        <AddImgWrapp1 className="flexCenter">
          <img src={AddImage1} alt="office" />
        </AddImgWrapp1>
        <AddImgWrapp2>
          <img src={AddImage2} alt="office" />
        </AddImgWrapp2>
      </div>
      <div className="flexNullCenter">
        <AddImgWrapp3>
          <img src={AddImage3} alt="office" />
        </AddImgWrapp3>
        <AddImgWrapp4>
          <img src={AddImage4} alt="office" />
        </AddImgWrapp4>
      </div>
    </AddRightInner>
  </AddRight>
</Advertising>

          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
