import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={39.581} height={39.58} viewBox="0 0 39.581 39.58" {...props}>
      <g data-name="Group 72">
        <path
          data-name="Path 127"
          d="M19.791 0C8.87 0 0 7.014 0 15.662c0 3.708 1.695 7.1 4.49 9.601v6.455a1.937 1.937 0 002.985 1.7l7.314-5.386c1.519.437 3.137.664 4.931.664 10.92 0 19.79-7.014 19.79-15.662S30.71 0 19.791 0zm0 28.67c-1.566 0-3.104-.182-4.565-.532a1.936 1.936 0 00-1.585.272l-5.346 3.937v-3.859a1.937 1.937 0 00-.681-1.448C4.85 24.999 3.507 20.51 3.507 15.662c0-6.496 7.29-11.787 16.284-11.787s16.285 5.291 16.285 11.787S29.572 28.67 19.791 28.67z"
          fill="#4cd5c5"
        />
        <path
          data-name="Inner Path"
          d="M19.791 3.658c-8.153 0-14.786 4.705-14.786 11.787 0 3.78 1.771 7.04 4.66 9.163a1.937 1.937 0 01.707 1.448v3.89l4.719-3.474a1.937 1.937 0 011.585-.272c1.46.35 2.998.532 4.565.532 8.153 0 14.785-4.705 14.785-11.787S27.945 3.658 19.791 3.658z"
          fill="#e0f7f4"
        />
        <g data-name="Group 69" fill="#0b093b">
          <path
            data-name="Path 128"
            d="M19.791 2.44c-9.107 0-16.285 5.256-16.285 11.787 0 4.703 2.08 8.747 5.489 11.073v4.527l5.346-3.937a1.936 1.936 0 011.585-.272c1.46.35 2.998.532 4.565.532 9.107 0 16.285-5.255 16.285-11.787 0-6.531-7.178-11.787-16.285-11.787zm0 25.663c-1.795 0-3.412-.228-4.931-.664l-7.314 5.386a1.937 1.937 0 01-2.985-1.7v-6.455C1.695 22.762 0 19.37 0 15.662 0 7.014 8.87 0 19.791 0s19.791 7.014 19.791 15.662-8.87 15.662-19.791 15.662z"
          />
          <path
            data-name="Path 129"
            d="M25.905 3.182a3.072 3.072 0 103.071 3.072 3.075 3.075 0 00-3.071-3.072zm0 4.378a1.307 1.307 0 111.307-1.305 1.314 1.314 0 01-1.305 1.306z"
          />
          <path
            data-name="Path 130"
            d="M32.673 3.182a3.072 3.072 0 103.07 3.072 3.073 3.073 0 00-3.07-3.072zm0 4.378a1.307 1.307 0 111.307-1.305 1.315 1.315 0 01-1.305 1.306z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
