import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
            <p className="font13">
              Explore insightful stories and updates from the world of audio guides and immersive experiences.
              <br />
              Discover how Kupeel transforms audio content for museums and beyond.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Museum Partnerships"
                text="Learn about our latest collaborations with museums around the globe to enhance visitor experiences."
                tag="partnership"
                author="Kupeel Team, 2 days ago"
                action={() => window.location.href = 'https://play.kupeel.com'}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Innovative Audioguides"
                text="Discover the innovative approaches we take to create engaging and informative audioguides."
                tag="innovation"
                author="Kupeel Team, 5 days ago"
                action={() => window.location.href = 'https://play.kupeel.com'}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="User Stories"
                text="Read stories from our users and how Kupeel has transformed their audio content creation."
                tag="user stories"
                author="Kupeel Team, 1 week ago"
                action={() => window.location.href = 'https://play.kupeel.com'}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => window.location.href = 'https://play.kupeel.com'} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Hear from museums and companies about their experiences with Kupeel.
              <br />
              Transforming audio content has never been easier or more impactful.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
