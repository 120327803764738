import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13">
              Explore our flexible pricing plans designed to meet the needs of museums and cultural institutions.
              <br />
              Enhance your visitor experience with Kupeel's innovative audio solutions.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="browser"
                price="Free"
                title="Free"
                text="Listen to thousands of episodes with no generation available."
                offers={[
                  { name: "No generation available", checked: true },
                  { name: "Listen to thousands of episodes", checked: true },
                  { name: "Daily episodes generated", checked: true },
                ]}
                action={() => window.location.href = 'https://play.kupeel.com'}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="£33/mo"
                title="Pro"
                text="40 minutes of audio generation per month. Ideal for small exhibits."
                offers={[
                  { name: "40 minutes of audio generation per month", checked: true },
                  { name: "Access to non-premium voices", checked: true },
                  { name: "Public access available", checked: true },
                ]}
                action={() => window.location.href = 'https://play.kupeel.com'}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="£330/mo"
                title="Studio"
                text="300 minutes of audio generation per month. Perfect for larger exhibits."
                offers={[
                  { name: "300 minutes of audio generation per month", checked: true },
                  { name: "Access to premium voices", checked: true },
                  { name: "Public access available", checked: true },
                  { name: "RSS feed and deployment on other platforms", checked: true },
                  { name: "Personalization options", checked: true },
                ]}
                action={() => window.location.href = 'https://play.kupeel.com'}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto 20px;
  }
`;
