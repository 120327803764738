import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={35.459} height={43.42} viewBox="0 0 64 64" {...props}>
      <path d="M32 40c4.418 0 8-3.582 8-8V8c0-4.418-3.582-8-8-8s-8 3.582-8 8v24c0 4.418 3.582 8 8 8z" fill="#4CAF50"/>
      <path d="M48 24v8c0 8.837-7.163 16-16 16S16 40.837 16 32v-8c0-1.105-.895-2-2-2s-2 .895-2 2v8c0 10.493 8.034 19.157 18 19.938V60h-6c-1.105 0-2 .895-2 2s.895 2 2 2h16c1.105 0 2-.895 2-2s-.895-2-2-2h-6v-8.062C46.966 51.157 55 42.493 55 32v-8c0-1.105-.895-2-2-2s-2 .895-2 2z" fill="#333333"/>
    </svg>
  );
}

export default SvgComponent;
